import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Box, Button, Grid, Paper, Typography } from "@material-ui/core"
import Lightbox from "react-image-lightbox"
import Layout from "../components/layout/main"
import Lead from "../components/global/text/lead"

import LightboxImageWrapperCard from "../components/global/lightbox/lightbox-image-wrapper-card"
import FiltreGaleri from "../components/global/carousel/filtregaleri"
import LightboxImageWrapper from "../components/global/lightbox/lightbox-image-wrapper"
import Seo from "../components/global/seo/seo.jsx"
const LaboratuvarAnalizElegi = () => {
  const data = useStaticQuery(graphql`
    query {
      resim1: file(relativePath: { eq: "filtre-galeri/analizelegi3.jpg" }) {
        childImageSharp {
          thumb: fluid(maxWidth: 450) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
          full: fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      resim2: file(relativePath: { eq: "filtre-galeri/analizelegi.jpg" }) {
        childImageSharp {
          thumb: fluid(maxWidth: 360) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
          full: fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      resim3: file(relativePath: { eq: "filtre-galeri/analizelegi4.jpg" }) {
        childImageSharp {
          thumb: fluid(maxWidth: 360) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
          full: fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      resim4: file(relativePath: { eq: "filtre-galeri/analizelegi6.jpg" }) {
        childImageSharp {
          thumb: fluid(maxWidth: 360) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
          full: fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const fullImgArray = [
    data.resim1.childImageSharp.full.src,
    data.resim2.childImageSharp.full.src,
    data.resim3.childImageSharp.full.src,
    data.resim4.childImageSharp.full.src,
  ]
  const thumbArray = [
    { imgName: "resim2", imgAlt: "30cm Çap Laboratuvar Test ve Analiz Eleği" },
    { imgName: "resim3", imgAlt: "40cm Çap Laboratuvar Test ve Analiz Eleği" },
    { imgName: "resim4", imgAlt: "50cm Çap Laboratuvar Test ve Analiz Eleği" },
  ]
  const [isOpen, toggleOpenLightbox] = React.useState(false)
  const toggleLightbox = opened => {
    toggleOpenLightbox(opened)
  }
  const [imgIndex, setIndex] = React.useState(0)
  const setImgIndex = imageIndex => {
    setIndex(imageIndex)
  }
  const openLightBox = iIndex => {
    toggleLightbox(true)
    setImgIndex(iIndex)
  }
  const setPrevImgIndex = () => {
    const newIndex = (imgIndex + fullImgArray.length - 1) % fullImgArray.length
    setImgIndex(newIndex)
  }
  const setNextImgIndex = () => {
    const newIndex = (imgIndex + 1) % fullImgArray.length
    setImgIndex(newIndex)
  }
  return (
    <Layout>
      <Seo
        title="Laboratuvar Analiz Test Eleği - Paslanmaz Filtre Erez Kaynak " 
        description="Laboratuvar Analiz Test Eleği Firmamız laboratuvar analiz test eleklerini standart olarak Ø20cm, Ø30cm, Ø40cm ve Ø50cm ebatlarında , müşterinin istediği gözde paslanmaz mesh elek teli yada delikli saç  kullanarak imalatını yapmaktadır. Detaylı bilgi yada fiyat teklifi almak için bizi arayınız."
        keywords="Laboratuvar Analiz Test Eleği,Laboratuvar eleği,Laboratuvar test elekleri,Laboratuvar test eleği,Laboratuvar analiz elekleri,Laboratuvar elekleri,Laboratuvar analiz eleği imalatı,20cm Laboratuvar test eleği,30cm Laboratuvar test eleği,40cm Laboratuvar test eleği,50cm Laboratuvar test eleği,20cm Laboratuvar analiz eleği,30cm Laboratuvar analiz eleği,40cm Laboratuvar analiz eleği,50cm Laboratuvar analiz eleği"
        url="laboratuvar-analiz-test-elegi"
        imgurl={data.resim1.childImageSharp.thumb.src}
      />

      <Box py={3}>
        <Paper elevation={4}>
          <Grid container direction="row" alignItems="center">
            <Grid item xs={12} sm={12} md={7} lg={7}>
              <Box p={3}>
                <Typography component="h1" variant="h4">
                  <Box textAlign="center" fontWeight={500} mt={3}>
                    Laboratuvar Analiz Test Eleği
                  </Box>
                </Typography>
                <Lead>
                  Firmamız <span>laboratuvar analiz test eleklerini</span>{" "}
                  standart olarak Ø20cm, Ø30cm, Ø40cm ve Ø50cm ebatlarında ,
                  müşterinin istediği gözde paslanmaz mesh elek teli yada
                  delikli saç kullanarak imalatını yapmaktadır.
                </Lead>

                <Lead>
                  Detaylı bilgi ya da fiyat teklifi almak için bizi arayınız.
                </Lead>
                <Box textAlign="center" py={3}>
                  <Link to="/iletisim" style={{ textDecoration: "none" }}>
                    <Button variant="outlined" color="secondary">
                      İLETİŞİM BİLGİLERİ
                    </Button>
                  </Link>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={5}>
              <Box px={3} py={3}>
                <div onClick={() => openLightBox(0)}>
                  <LightboxImageWrapper
                    fluid={data.resim1.childImageSharp.thumb}
                    imgAlt="20cm Çap Laboratuvar Test ve Analiz Eleği"
                    imgClass=""
                  />
                </div>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Box>
      <Box py={3}>
        <Grid container direction="row" spacing={3}>
          {thumbArray.map((resim, index) => (
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <div onClick={() => openLightBox(index + 1)}>
                <LightboxImageWrapperCard
                  fluid={data[resim.imgName].childImageSharp.thumb}
                  lightbox={true}
                  imgAlt={resim.imgAlt}
                />
              </div>
            </Grid>
          ))}
        </Grid>
      </Box>

      <Box py={3}>
        <FiltreGaleri />
      </Box>

      {isOpen && (
        <Lightbox
          mainSrc={fullImgArray[imgIndex]}
          nextSrc={fullImgArray[(imgIndex + 1) % fullImgArray.length]}
          prevSrc={
            fullImgArray[
              (imgIndex + fullImgArray.length - 1) % fullImgArray.length
            ]
          }
          onCloseRequest={() => toggleLightbox(false)}
          onMovePrevRequest={setPrevImgIndex}
          onMoveNextRequest={setNextImgIndex}
        />
      )}
    </Layout>
  )
}
export default LaboratuvarAnalizElegi
